@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import './Products.module.css' */
/* THEME VARIABLES */
@layer base {

    html,
    body {
        font-family: "Muli Regular";
        background-color: #F4FDFF;
    }

    :root {
        --color-text-base: #182444;
        --color-text-muted: #D6F9FF;
        --color-fill: #FFFFFF;
        --color-button-accent-fill: #D1EBF0;
        --color-primary: #005F73;
        --color-secondary: #43C4DB;
        --color-tertiary: #EAD2AC;
        --color-hover: #F44336;
        --color-neutral: #9BAE37;
        /* option colors */
        --color-red: #F95738;
        --color-green: #B6C954;
        --color-badge-green: #CAD883;
        --color-badge-yellow: #FFC233;
        --color-badge-red: #F98C76;
        /* Thme Gray colors */
        --color-line-gray: #DADCE0;
        --color-highlight-gray: #F2F2F2;
        --color-com-bg: #F6F6F6;
        --color-text-sub: #9F9F9F;
        /** Product Tab and button bg colors */
        --primary-background: rgba(0, 95, 115, 0.13);
        --secondary-background: rgba(67, 196, 219, 0.27);
        --tertiary-background: rgba(5, 4, 3, 0.25);
        --option-background: rgba(255, 194, 51, 0.2);
        /** Sidemenu background **/
        --sidemenu-background: #FFFFFF;
        /* Shadow effect */
        --main-shadow-color: 0px 0px 6px rgba(0, 0, 0, 0.15);
        --simple-shadow-color: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    }

    .light {
        --color-text-base: #182444;
        --color-text-muted: #D6F9FF;
        --color-fill: #FFFFFF;
        --color-button-accent-fill: #D1EBF0;
        --color-primary: #005F73;
        --color-secondary: #43C4DB;
        --color-tertiary: #EAD2AC;
        --color-hover: #F44336;
        --color-neutral: #9BAE37;
        /* option colors */
        --color-red: #F95738;
        --color-green: #B6C954;
        --color-badge-green: #CAD883;
        --color-badge-yellow: #FFC233;
        --color-badge-red: #F98C76;
        /* Thme Gray colors */
        --color-line-gray: #DADCE0;
        --color-highlight-gray: #F2F2F2;
        --color-com-bg: #F6F6F6;
        --color-text-sub: #9F9F9F;
        /** Product Tab and button bg colors */
        --primary-background: rgba(0, 95, 115, 0.13);
        --secondary-background: rgba(67, 196, 219, 0.27);
        --tertiary-background: rgba(234, 210, 172, 0.39);
        --option-background: rgba(255, 194, 51, 0.25);
        /** Sidemenu background **/
        --sidemenu-background: #FFFFFF;
        /* Shadow affect */
        --main-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.15);
        --simple-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    }

    .dark {
        --color-text-base: #FFFFFF;
        --color-text-muted: #D6F9FF;
        --color-fill: #07343C;
        --color-button-accent-fill: #D1EBF0;
        --color-hover: #F44336;
        --color-primary: #43C4DB;
        --color-secondary: #005F73;
        --color-tertiary: #EAD2AC;
        /* option colors */
        --color-red: #F95738;
        --color-green: #B6C954;
        --color-badge-green: #CAD883;
        --color-badge-yellow: #FFC233;
        --color-badge-red: #F98C76;
        /* Thme Gray colors */
        --color-line-gray: #DADCE0;
        --color-highlight-gray: #F2F2F2;
        --color-com-bg: #F6F6F6;
        --color-text-sub: #9F9F9F;
        /** Product Tab and button bg colors */
        --primary-background: #2F3B52;
        --secondary-background: #2F3B52;
        --tertiary-background: #2F3B52;
        --option-background: #2F3B52;
        /** Sidemenu background **/
        --sidemenu-background: #2F3B52;
        /* Shadow affect */
        --main-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.15);
        --simple-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    /* CUSTOM FONT MULI */
    @font-face {
        font-family: 'Muli Regular';
        font-weight: 400;
        src: local(''), url("../asset/muli/Muli-Bold.ttf") format('truetype');
    }

    @font-face {
        font-family: "Muli ExtraLight";
        font-weight: 200;
        src: local(""), url("../asset/muli/Muli-ExtraLight.ttf") format("truetype");
    }

    @font-face {
        font-family: "Muli Regular";
        font-weight: 300;
        src: local(""), url("../asset/muli/Muli-Light.ttf") format("truetype");
    }

    @font-face {
        font-family: "Muli light";
        font-weight: 300;
        src: local(""), url("../asset/muli/Muli-Light.ttf") format("truetype");
    }

    @font-face {
        font-family: "Muli Bold";
        font-weight: 700;
        src: local(""), url("../asset/muli/Muli-Bold.ttf") format("truetype");
    }

    @font-face {
        font-family: "Muli SemiBold";
        font-weight: 600;
        src: local(""), url("../asset/muli/Muli-SemiBold.ttf") format("truetype");
    }
}

@layer components {
    .sidebar-li-hover {
        @apply hover:bg-[#D6F9FF];
    }

    .sidebar-li-active {
        @apply bg-[#D6F9FF];
    }

    .sidebar-li-hover span,
    .sidebar-li-hover svg {
        @apply text-skin-primary;
    }

    .primary-button {
        @apply bg-skin-primary text-white transition delay-75 duration-200;
    }

    .primarybg-button {
        @apply bg-skin-primarybg text-white transition delay-75 duration-200;
    }

    input[type="checkbox"] {
        @apply rounded;
    }

    input[type="checkbox"]:checked,
    [type="radio"]:checked {
        @apply bg-skin-primary;
    }

    input[type="checkbox"]:focus {
        @apply ring-0 ring-inset;
    }

    input[type="checkbox"]:indeterminate:hover,
    input[type="checkbox"]:indeterminate:focus,
    input[type="checkbox"]:indeterminate {
        @apply bg-skin-primary;
    }

    input[type="checkbox"]:checked:hover,
    input[type="checkbox"]:checked:focus,
    input[type="radio"]:checked:hover,
    input[type="radio"]:checked:focus {
        @apply bg-skin-primary border-none text-skin-primary;
    }

    /*Sidebar */
    .sidebar-hover .hover-active {
        @apply flex;
    }

    .sidebar-hide .hover-active {
        @apply hidden;
    }

    .sidebar-hover .hidecontent {
        @apply hidden;
    }

    .sidebar-hide .hidecontent {
        @apply flex;
    }

    /* Badge */
    .pill-green-rounded {
        @apply bg-skin-badge-green py-0.5 px-1 font-muli rounded-5 text-sm;
    }

    .pill-red-rounded {
        @apply bg-skin-badge-red py-0.5 px-1 font-muli rounded-5 text-sm;
    }

    .pill-gray-rounded {
        @apply bg-skin-line py-0.5 px-1 font-muli rounded-5 text-sm;
    }
}

/** Data Table */
.rdt_TableRow input[type="checkbox"],
.rdt_TableCol input[type="checkbox"] {
    @apply w-5 h-5;
}

.rdt_TableRow:hover {
    @apply bg-skin-commonbg;
}

div.rdt_TableBody {
    @apply border-b-skin-line border-b;
}

.rdt_TableHead.idPHNo {
    @apply text-lg text-skin-base font-mulisemibold;
}

.rdt_TableBody div {
    @apply text-base text-skin-base font-muli;
}

div.rdt_TableCol_Sortable:hover {
    @apply opacity-100;
}

span.badge-text {
    @apply text-xs;
}

div.rdt_TableCell,
div.rdt_TableCol {
    @apply px-2;
}

input[type="checkbox"] {
    @apply border-skin-base border-2;
}

input[type="checkbox"]:checked,
[type="radio"]:checked,
input[type="checkbox"]:focus,
[type="radio"]:focus {
    @apply border-skin-primary border-2;
    box-shadow: none;
}

input[type="text"]:focus {
    @apply text-skin-base;
    box-shadow: none;
}

nav.rdt_Pagination {
    @apply justify-start;
}

.removeBg {
    @apply px-0 py-0 bg-none shadow-none bg-transparent border-none;
}

.temp {
    @apply text-black text-[20px] px-0;
}

.dashboardPadding {
    @apply px-0 mt-[30px];
}

.order-padding {
    @apply px-0 py-0;
}

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        display: block;
        width: 10px;
        height: 5px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: transparent;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #005F73;
        border-radius: 100vh;
        border: 3px solid #FFFFFF;
        height: 5px;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #005F73;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.quantity::-webkit-outer-spin-button,
.quantity::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.container {
    width: 100%;
    max-width: 1440px;
    padding-left: 40px;
    padding-right: 40px;
}

@media (max-width: 639px) {
    .container {
        /* Mobile-specific styles here */
        padding-left: 10px;
        padding-right: 10px;
    }
}

/* Styles for screen sizes between `sm` and `md` (inclusive) */
@media (min-width: 640px) and (max-width: 767px) {
    .container {
        /* Tablet-specific styles here */
        padding-left: 20px;
        padding-right: 20px;
    }
}

div.Select-control>.Select-value {
    background-color: rgba(153, 153, 153, .08);
    border: 1px solid rgba(153, 153, 153, 0.24);
    color: red;
}

div.Select-control>.Select-value>.Select-value-icon {
    border-right: 1px solid rgba(153, 153, 153, 0.24);
}

div.Select-control>.Select-value>.Select-value-label,
.Select-value>a {
    color: red;
}

.table {
    border: 1px solid #43C4DB;
    border-color: #43C4DB;
    border-radius: 5px;
    background: #fff;
}

.table tbody>tr:nth-last-child(1) {
    border: none;
}

.table-heading-bg {
    background-color: #D6F9FF;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

textarea::-webkit-input-placeholder {
    color: #9F9F9F;
    font-weight: 600;
}

textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #9F9F9F;
    font-weight: 600;
}

textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #9F9F9F;
    font-weight: 600;
}

textarea:-ms-input-placeholder {
    color: #9F9F9F;
    font-weight: 600;
}

/* select.orderPageStatus::before {
  content: "";
  display: block;
  background: url("./../public/order-status-fullfilled.png") no-repeat;
  width: 10px;
  height: 10px;
  float: left;
  margin: 0 5px 0 0;
} */
.dashboardPadding {
    padding: 0 !important;
    width: 100% !important;
}

.active {
    border-bottom-width: 2px;
    /* border: solid; */
    border-bottom-color: var(--color-primary);
}