:root {
  --body-bg: #f5f8ff;
  --box-bg: #fff;
  --input-bg: #f5f8ff;
  --txt-color: #2f2d2f;
  --txt-second-color: #ccc;
  --border-color: #4267b2;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --primary-color: #000000;
  --secondary-color: #2d343d;
  --tertiary-color: #0099d8;
  --primary-btn-color: #0099d8;
  --primary-btn-hover-color: #00aef2;
  --primary-border-color: #0099d8;
  --secondary-btn-color: #0099d8;
  --primary-bg-color: #fff;
  --button-gradient-color: linear-gradient(to right, #0178bc 0%, #00bdda 100%);
  --button-text-color: #fff;
  --slider-bg-color: #fff;
  --bg-community: #0099d8;
  --menu-text-color: #fff;
  --input-border-color: 1px solid #d7d7d7;
  --input-text-color: #424040;
  --light-blue: #f6f9fc;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


.required-input::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.button_animi {
  display: block;
  position: relative;
  margin: 0 auto 0;
  padding: 10px 0;
  width: 100%;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  /* background-color:  !important; */
  border: none;
}

.button_animi:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.button_animi:hover:after {
  width: 120%;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2rem);
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

.fade-out {
  animation: fadeOut 0.3s ease-in-out;
}

.menu {
  animation: fadeIn 0.2s ease-in-out;
}

.pagination,
.pagination-pager {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.pagination {
  border-radius: 0.375rem;
  z-index: 0;
}

.pagination-total {
  --tw-text-opacity: 1;

  color: rgba(107, 114, 128, var(--tw-text-opacity));
  margin-right: 0.75rem;
}

.pagination-pager-prev {
  margin-left: 0.25rem;
}

.pagination-pager-disabled {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
  cursor: not-allowed;
}

.pagination-pager {
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.875rem;
  height: 2rem;
  justify-content: center;
  line-height: 1.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  -webkit-user-select: none;
  user-select: none;
  width: 2rem;
}

.pagination,
.pagination-pager {
  align-items: center;
  display: inline-flex;
  position: relative;
}


ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.pagination-pager-inactive {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s;
  /* Opacity will fade smoothly */
}

.modal--invisible {
  opacity: 0;
  pointer-events: none;
  /* Makes the modal unclickable */
}

.modal--visible {
  opacity: 1;
  /* Modal is visible */
  pointer-events: initial;
  /* Modal is clickable */
}

.ql-container.ql-snow {
  /* border-radius: 10px; */
  color: #182444;
  font-family: "Muli light";
  min-height: 100px !important;
}

.user-profile {
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding-bottom: 14px;
  border-radius: 50%;
}

.dropdown-menu {
  min-width: 160px;
  padding: 0.375rem;
  position: absolute;
  z-index: 30;
  border-radius: 10px;
}

.dropdown-menu.bottom-end {
  right: 0;
  margin-right: 2%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.375rem;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1),
    0 0 transparent;
  border: 1px solid rgba(229, 231, 235, 1);
}